.wrapper {
  max-width: 72.0rem;
  margin: 0 auto;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 14rem 1.6rem;
  color: #1B1C20;

  h1 {
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 2rem;
  }
  
  h2 {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

@media screen and (max-width: 650px) {
  .wrapper {
    max-width: 720px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
    padding: 140px 16px;
  
    h1 {
      font-weight: 500;
      font-size: 22px;
      line-height: 20px;
    }
    
    h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}