.section1 {
  height: 92.9rem;
  background: #5484FF;
  border-bottom-left-radius: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 17.2rem;
  
  &Title {
    font-weight: 700;
    font-size: 3.8rem;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    max-width: 82.8rem;
    & span {
      color: #F7CE38;
    }
  }
  
  &Subtitle {
    max-width: 76.7rem;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3.1rem;
    margin-top: 2.4rem;
    text-align: center;
    color: #FFFFFF;
  }
  
  &Button {
    background: #F7CE38;
    color: #000000;
    width: 27.6rem;
    height: 5.8rem;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    padding: 1.7rem 6.1rem;
    &:hover {
      background: darken(#F7CE38, 12);
    }
  }
  
  &ButtonRow {
    margin-top: 4.8rem;
  }
}

.section2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 9.8rem;
  padding-bottom: 11rem;
  row-gap: 2.4rem;

  &Title {
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 3.2rem;
    color: #1B1C20;
    margin-bottom: 1.6rem;
  }
}

.collapsibleRow {
  background: #F3F8FF;
  border-radius: 13px;
  max-width: 102.4rem;
  width: 100%;
  user-select: none;
  
  .collapsibleRowIcon {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
  }
  
  &.active {
    .collapsibleRowContent {
      display: block;
    }
    
    .collapsibleRowIcon {
      transform: rotate(180deg);
    }
  }
  
  &Title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.2rem;
    color: #1B1C20;
    padding: 3.7rem 6rem;
    cursor: pointer;
  }
  
  &Content {
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3.2rem;
    color: #1B1C20;
    padding: 0 6rem 3.6rem 6rem;
    display: none;
  }
}

.hideSm {
  @media (max-width: 768px) {
    display: none;
  }
}
.showSm {
  @media (min-width: 768px) {
    display: none;
  }
}

@media (max-width: 768px) {
  .section1 {
    height: 672px;
    padding-top: 10rem;
    border-bottom-left-radius: 42px;
    padding: 98px 20px 46px 20px;
    width: 100%;

    &Title {
      font-weight: 700;
      font-size: 28px;
      line-height: 120%;
      text-align: center;
    }
    &Subtitle {
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      margin-top: 16px;
      text-align: center;
    }
    &ButtonRow {
      width: 100%;
      margin-top: 16px;
    }

    &Button {
      width: 100%;
      font-weight: 700;
      font-size: 16px;
      padding: 13px 0px;
      height: 50px;
    }
  }
  
  .section2 {
    padding: 64px 20px 76px 20px;
    row-gap: 20px;
    
    &Title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      margin: 0;
    }
  }
  
  .collapsibleRow {
    &Title {
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      color: #1B1C20;
      padding: 20px;
    }
    &Content {
      padding: 0 20px 20px 20px;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: #1B1C20;
    }
  }
}