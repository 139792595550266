@font-face {
  font-family: 'Montserrat';
  src: url('./static/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./static/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./static/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./static/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./static/fonts/DMSans-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./static/fonts/DMSans-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./static/fonts/DMSans-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./static/fonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./static/fonts/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./static/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./static/fonts/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Adobe Garamond Pro';
  src: url('./static/fonts/AGaramondPro-Regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Adobe Garamond Pro';
  src: url('./static/fonts/AGaramondPro-Bold.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
}

html,
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

div,
input {
  box-sizing: border-box;
}

img {
  image-rendering: -webkit-optimize-contrast;
  will-change: transform;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.SnackbarContainer-root {
  width: 100% !important;
  max-width: 100% !important;
  top: 0px !important;
  background: linear-gradient(180deg, rgba(43, 69, 107, 0.38) 0%, rgba(43, 69, 107, 0) 100%);
  height: 245px;
}

h1 {
  margin: 0;
}
