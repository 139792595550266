html {
  color: #292929;
  background: #f4f7fa;
}

:global {
  .MuiButton-outlined, .btnIconHack{
    .btn_icon_one {
      display: block;
      padding-right: 13px;
    }
    .btn_icon_two {
      display: none;
      padding-right: 13px;
    }

    &:hover {
      .btn_icon_one {
        display: none;
      }
      .btn_icon_two {
        display: block;
      }
    }
  }
}

div,
input,
button,
a,
p,
h1,
h2,
h3,
h4,
h5 {
  font-family: inherit;
}

p {
  line-height: 24px;
}
a {
  color: #292929;
}

@for $i from 0 through 20 {
  $value: $i * 5;

  .mt#{$value} {
    margin-top: #{$value}px;
  }
  .mb#{$value} {
    margin-bottom: #{$value}px;
  }
  .ml#{$value} {
    margin-left: #{$value}px;
  }
  .mr#{$value} {
    margin-right: #{$value}px;
  }

  .pt#{$value} {
    padding-top: #{$value}px;
  }
  .pb#{$value} {
    padding-bottom: #{$value}px;
  }
  .pl#{$value} {
    padding-left: #{$value}px;
  }
  .pr#{$value} {
    padding-right: #{$value}px;
  }
}

.ml8 {
  margin-left: 8px;
}

.flexCenter {
  display: flex;
  width: 100%;
  justify-content: center;
}

.verticalFlexCenter {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.fadeIn {
  animation: fadeIn linear 0.2s;
}

.checkbox {
  margin-left: -12px;

  &:hover {
    background-color: rgba(41, 41, 41, 0.04) !important;
  }

  &Wrapper label {
    margin-top: -1px;
    user-select: none;
  }
}

.labelSm {
  font-size: 14px;
}

.sectionTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  // text-transform: uppercase;
  color: #000000;
  margin: 0;
}

.input {
  font-family: inherit;
  width: 100%;
  box-sizing: border-box;
  height: 55px;
  font-size: 18px;
  // background: #F5F5F5;
  background-color: #ffffff;
  border: 1.5px solid #e3e3e3;
  border-radius: 6px;
  padding: 15px;
  padding-right: 18px;
  transition: border 0.15s ease-out;
  resize: none;

  &:hover {
    border: 1.5px solid #5484ff !important;
  }

  &:focus {
    outline: none;
    border: 1.5px solid #5484ff !important;
  }

  &:active {
    outline: none;
    border: 1.5px solid #5484ff !important;
  }

  &::placeholder {
    color: #939393;
  }

  &Error {
    border: 1.5px solid #dc3545 !important;
  }
  &SelectError {
    border: 1px solid #dc3545 !important;
  }

  &Sm {
    height: 50px;
  }

  &WithButton {
    display: flex;

    .input {
      flex-grow: 1;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.montseratFont {
  font-family: 'Montserrat', sans-serif;
}

.thinButtonText {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.formControl {
  width: 100%;
  height: 55px;
  font-family: inherit;
  font-size: 18px;
  line-height: 24px;
  border-radius: 6px;
  outline: none;
  box-shadow: none;
  input {
    font-size: 18px;
    line-height: 24px;
  }
  fieldset {
    border-color: #e3e3e3;
    border-width: 1.5px;
    transition: border-color 0.1s ease-out;
  }
  &:hover {
    fieldset {
      border-color: #5484ff !important;
      border-width: 1.5px !important;
    }
  }
  :global {
    .MuiOutlinedInput-root {
      height: 55px;
    }
  }
}

.p15 {
  padding: 15px;
}

.selectWithFlex {
  :global {
    .MuiOutlinedInput-input {
      display: flex;
      align-items: center;
      padding-left: 5px;
      font-family: inherit;
      img {
        width: 32px;
        margin-right: 12px;
      }
    }
  }
}

.selectWithFlexRounded {
  padding-left: 15px;
  // border-radius: 100px;

  :global {
    .MuiOutlinedInput-input {
      display: flex;
      align-items: center;
      padding-left: 5px;
      font-family: inherit;
      img {
        width: 24px;
        margin-right: 0px;
      }
    }
  }
}

:global {
  .MuiListItemText-primary {
    font-family: 'Montserrat', sans-serif !important;
  }
}

.selectWithTeamCounter {
  :global {
    .MuiOutlinedInput-input {
      font-family: inherit;
      display: flex;
      align-items: center;
      padding-left: 0px;
    }
  }
}

.menuItem {
  height: 55px;
  font-size: 18px !important;

  &WithImage {
    img {
      width: 32px;
      margin-right: 12px;
    }
  }

  &WithIcon {
    padding-left: 20px;
    padding-right: 65px;
    padding-top: 35px;
    padding-bottom: 35px;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 1px solid #e3e3e3;
    img {
      margin-top: -2px;
      margin-right: 15px;
    }
  }

  &WithIcon:last-child {
    border-bottom: none;
  }

  &Static {
    font-weight: bold;
    cursor: default;
    pointer-events: none;
    padding: 0;
  }
}

:global {
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #5484ff !important;
    border-width: 1.5px !important;
  }

  .MuiList-root {
    padding: 0 !important;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
  }

  .MuiMenu-root .MuiPaper-root {
    margin-top: -13px;
    box-shadow: 0px 36px 52px rgba(52, 79, 150, 0.15) !important;
  }

  .MuiSelect-outlined {
    padding-left: 0 !important;
  }

  .Mui-selected {
    font-weight: bold;
  }

  .Mui-disabled {
    font-weight: normal;
    opacity: 0.5 !important;
  }

  .MuiPickersDay-root {
    font-size: 14px;
    line-height: 14px;
    font-family: inherit;
  }

  .MuiPickersDay-root.Mui-selected {
    background-color: #5484ff !important;
  }

  .MuiPaper-elevation8 {
    box-shadow: 0px 36px 52px rgba(52, 79, 150, 0.15) !important;
  }
}

.titleInput {
  font-size: 20px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0);
  transition: border 0.15s ease-out;
  color: #292929;
  padding: 5px 5px;
  font-weight: bold;
  border-radius: 500px;
  transition: background 0.15s ease-out;

  input::placeholder {
    opacity: 1;
    color: #292929;
  }

  &:hover input::placeholder {
    opacity: 0.6;
    color: #292929;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #f5f5f5 inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #f5f5f5 inset !important;
}

.button {
  background: #5484ff;
  color: #fff;
  border-radius: 6px;
  font-size: 18px;
  height: 50px;
  line-height: 26px;
  padding: 0 30px;
  border: none;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s ease-out;
  text-transform: none;
  font-family: inherit;

  &:hover {
    background: #3666e1;
  }

  &:disabled {
    color: #fff;
  }

  &Lg {
    &:disabled {
      color: #a6b6cb;
      background: #e9eef5;
    }
  }

  &Big {
    border: 1px solid #5484ff;
    height: 55px !important;
  }

  &Outline {
    background: #fff !important;
    color: #5484ff !important;
    border: 1px solid #5484ff;

    &:hover {
      background: #5484ff !important;
      color: #fff !important;
    }

    &Pale {
      border: 1px solid #dadfeb;
    }

    &Gray {
      border: 1px solid #dadfeb !important;
      color: #000;
      &Active {
        border: 1px solid #5484ff;
        background: #5484ff !important;
        color: #fff;
      }
    }

    &Error {
      border: 1px solid #dc3545 !important;
    }
    &BigHack {
      border: 1px solid #5484ff;
      height: 55px !important;

      // img#add_emp_btn {
      //   visibility: hidden;
      //   &:hover {
      //     visibility: visible;
      //   }
      // }
      // img#add_emp_btn_white {
      //   display: none !important;
      // }
      // &:hover {
      //   #add_emp_btn {
      //     display: none !important;
      //   }
      //   #add_emp_btn_white {
      //     display: block!important;
      //   }
      // }
    }
  }

  &Sm {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    height: 44px;
    line-height: 44px;
    white-space: nowrap;
    padding: 0 45px;
    color: #ffffff;
    background: #5484ff;
    text-transform: none;
    border-radius: 22px;
    font-family: inherit;
    &:hover {
      background: darken($color: #5484ff, $amount: 6);
    }

    &WithIcon {
      padding: 0 20px;
    }

    &:disabled {
      background: #f3f8ff;
      opacity: 1 !important;
      color: #b0c0d5;
    }
  }

  &SuperSm {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    height: 26px;
    line-height: 16px;
    white-space: nowrap;
    padding: 0 34px;
    color: #ffffff;
    background: #5484ff;
    text-transform: none;
    border-radius: 22px;
    font-family: inherit;
    &:hover {
      background: darken($color: #5484ff, $amount: 6);
    }

    &WithIcon {
      padding: 0 20px;
    }

    &:disabled {
      background: #f3f8ff;
      opacity: 1 !important;
      color: #b0c0d5;
    }
  }

  &OutlineBlue {
    border: 1px solid #dadfeb;
    color: #5484ff;
    background: #fff;

    &:hover {
      background: #eef3ff;
    }
  }

  &BorderedSm {
    color: #292929;
    background: #fff;
    height: 45px;
    font-size: 16px;
    border: 2px solid #5484ff !important;

    &:hover {
      background: #eef3ff;
    }
  }

  &White {
    background: #ffffff;
    color: black;
  }

  &White:hover {
    background: darken(#ffffff, 5);
  }

  &Grey {
    background: #f3f8ff;
    color: black;
  }

  &Grey:hover {
    background: darken(#f3f8ff, 5);
  }

  &PaleBlue {
    background: #769dff;
    color: #fff;
  }

  &PaleBlue:hover {
    background: darken(#769dff, 3);
  }

  &Round {
    border-radius: 16px;
  }

  &Rounded {
    border-radius: 100px;
  }
  
  &Google {
    background: #FFF;
    color: #000;
    border: 2px solid #5484FF;
    &:hover {
      background: #F1F5FF;
    }
  }
}

.errorText {
  color: #dc3545;

  a {
    color: #dc3545;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wrapper {
  width: 100%;
  max-width: 600px;
  padding: 10px 30px 30px 30px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 6px;

  &Flex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 8vh;
    padding-bottom: 50px;
  }

  &Wide {
    max-width: 740px !important;
  }
}

.noMargin {
  margin: 0;
}
.fullWidth {
  width: 100%;
}
.floatRight {
  float: right;
}

.card {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: none;
  position: relative;

  &Pale {
    background: #f4f6fa;
    border: 1px solid #d6dbe3;
  }

  &LoadingBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: #f4f7fa;
    pointer-events: none;
    transition: opacity 0.15s ease-out;

    &Gradient {
      width: 400px;
      height: 100%;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: placeholderShimmer;
      animation-timing-function: linear;
      background: linear-gradient(
        to right,
        #f4f7fa 0%,
        #eaedf0 50%,
        #f4f7fa 100%
      );
    }
  }
}

@keyframes placeholderShimmer {
  0% {
    transform: translateX(-400px);
  }
  100% {
    transform: translateX(1000px);
  }
}

.text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  color: #000000;
}

.imgResponsive {
  width: 100%;
  height: auto;
}

.imgResponsive120 {
  width: 120%;
  height: auto;
}

.noPadding {
  padding: 0 !important;
}
.noMargin {
  margin: 0;
}
.fullWidth {
  width: 100%;
}

@media screen and (max-width: 650px) {
  .hideMd {
    display: none !important;
  }
}

.backButton {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  padding: 0;
  margin-left: -10px;
  text-transform: none;
  font-family: inherit;
  &:hover {
    background: none;
  }

  img {
    margin-bottom: 3px;
  }
}

.whiteBg {
  background: #fff;
}

.switchButton {
  &Wrapper {
    display: flex;
    flex-wrap: nowrap;
  }

  &Option {
    font-size: 14px;
    font-weight: normal;
    text-transform: none;
    color: #646464;
    text-transform: none;
    border: 1px solid #e3e3e3;
    padding: 8px 20px;
  }

  &SelectedOption {
    background: #f0f4ff;
    border: 1px solid #5484ff;
    color: #5484ff;
  }

  &LeftOption {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
  }

  &RightOption {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
  }
}

.formControlSm {
  width: 100%;
  height: 40px;
  font-family: inherit;
  font-size: 15px;
  line-height: 18px;
  border-radius: 6px;
  outline: none;
  box-shadow: none;
  background: #FFF;
  input {
    font-size: 15px;
    line-height: 18px;
  }
  fieldset {
    border-color: #e3e3e3;
    border-width: 1.5px;
    transition: border-color 0.1s ease-out;
  }
  &:hover {
    fieldset {
      border-color: #5484ff !important;
      border-width: 1.5px !important;
    }
  }
  :global {
    .MuiOutlinedInput-root {
      height: 55px;
    }
  }
}

.selectWithFlexRoundedSm {
  padding-left: 15px;
  // border-radius: 100px;

  :global {
    .MuiOutlinedInput-input {
      display: flex;
      align-items: center;
      padding-left: 5px;
      font-family: inherit;
      img {
        width: 24px;
        margin-right: 0px;
      }
    }
  }
}

.menuItemSm {
  height: 40px;
  font-size: 15px;
}