.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25.2rem;
  z-index: 1000;
  display: flex;
  align-items: flex-end;
  
  &Background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(43, 69, 107, 0.45) 0%, rgba(43, 69, 107, 0) 100%);
    opacity: 0.6;
  }
}

.card {
  background: #FFFFFF;
  border: 0.1rem solid #E3E3E3;
  box-shadow: 0rem 1.2rem 4.4rem rgba(52, 79, 150, 0.15);
  border-radius: 0.6rem;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #000000;
  height: 6.8rem;
  margin: 0 auto;
  width: 100%;
  max-width: 124rem;
  position: relative;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.6rem 0 5.9rem;
}

.button {
  border: 0.1rem solid #E3EDFB;
  border-radius: 7.3rem;
  width: 14.3rem;
  height: 4.4rem;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 4.3rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  
  &:last-child {
    background: #E0EDFF;
    border-color: #E0EDFF;
    
    &:hover {
      background: darken(#E0EDFF, 6);
    }
  }
  
  &:hover {
    background: darken(#FFFFFF, 6);
  }
}

.buttonWrapper {
  display: flex;
  column-gap: 1.6rem;
  align-items: center;
}

@media screen and (max-width: 650px) {
  .wrapper {
    height: 144px;
  }
  
  .card {
    border-radius: 0;
    width: 100%;
    height: 104px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    row-gap: 9px;
  }
  
  .button {
    width: 100px;
    height: 32px;
    line-height: 29px;
    font-weight: 700;
    font-size: 12px;
    
    &Wrapper {
      column-gap: 9px;
    }
  }
}