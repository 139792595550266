:global {
  // .calendly-overlay {
  //   position: absolute;
  //   width: 100%;
  //   height: 100vh;
  //   top: 0;
  //   left: 0;
  //   z-index: 1000;
  //   background: rgba(0, 0, 0, 0.5);
  // }

  // .calendly-popup-content {
  //   height: 100vh;
  // }

  // .calendly-popup-close {
  //   position: absolute;
  //   top: 20px;
  //   right: 20px;
  //   width: 40px;
  //   height: 40px;
  //   color: #FFF;
  // }

  .no-image {
    .MuiSelect-select {
      img {
        display: none;
      }
    }
  }
}

.header {
  padding: 0 1.6rem;
  height: 8.4rem;
  display: flex;
  justify-content: center;

  &Container {
    width: 100%;
    max-width: 128rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 13rem;
    }
  }
}

.menuWrapper {
  border: 1px solid #5484ff;
  border-radius: 6px;
}

.section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  font-family: 'DM Sans';
  display: flex;
  align-items: center;
  justify-content: center;

  &BadgesContainer {
    max-width: 90%;
    margin-top: -5rem;
  }

  &Column1 {
    width: 61.7rem;
    margin-right: 1rem;
  }

  &Column2 {
    width: 50rem;
  }

  &Title {
    font-weight: 700;
    font-size: 4.8rem;
    line-height: 5.5rem;
    color: #1b1c20;
    letter-spacing: -0.5px;
  }

  &Subtitle {
    padding-top: 2.4rem;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3.1rem;
    color: #1b1c20;
    max-width: 52.4rem;
  }
}

.review {
  max-width: 90%;
  flex-direction: row;
  gap: 1.6rem;
  padding: 3rem 2.4rem 3rem 2rem;
  background-color: #eef3ff;
  border: 1px solid #cfd9f2;
  border-radius: 2rem;
  margin-top: 4rem;
  @media screen and (max-width: 600px) {
    border-radius: 20px;
    padding: 20px;
    width: 100%;
    max-width: 100%;
    margin: 28px 0;
    gap: 16px;
  }

  &Content {
    width: 100%;
  }

  &Photo {
    width: 10.2rem;
    @media screen and (max-width: 600px) {
      width: 64px;
    }
    img {
      width: 100%;
    }
  }

  &Text {
    font-family: 'Roboto';
    color: #000000;
    font-size: 2rem;
    font-style: italic;
    font-weight: 400;
    line-height: 142.5%;
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  &Author {
    color: #1b1c20;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 134.375%;
    margin-top: 2rem;
    @media screen and (max-width: 600px) {
      font-size: 14px;
      margin-top: 12px;
    }
  }
}

.form {
  &Wrapper {
    width: 50rem;
    background: #ffffff;
    border: 0.2rem solid #5484ff;
    border-radius: 2rem;
    padding: 4rem;
    position: relative;
  }

  &Image1 {
    position: absolute;
    top: -1rem;
    left: -1rem;
    width: 7rem;
  }
  &Image2 {
    position: absolute;
    top: -7rem;
    right: -7rem;
    width: 9.4rem;
  }
  &Image3 {
    position: absolute;
    bottom: -6rem;
    left: -4rem;
    width: 7.2rem;
  }
  &Image4 {
    position: absolute;
    bottom: -1rem;
    right: -1rem;
    width: 7rem;
  }

  &Title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.1rem;
    color: #000000;
    text-align: center;
  }

  &Subtitle {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin-top: 2rem;
    text-align: center;
  }

  &Content {
    margin-top: 3rem;
  }

  &Button {
    margin-top: 4rem;
  }

  &Disclaimer {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-align: center;
    padding: 0 3rem;
    margin-top: 1.5rem;
  }
}

.input {
  width: 100%;
  height: 4.4rem;
  border: 0.2rem solid #f8f8fa;
  background: #f8f8fa;
  border-radius: 1rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 0 1.8rem;

  &Error {
    border-color: #ff685e;
  }
}

.menuItem {
  height: 4.4rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 3.3rem;
  font-family: DM Sans;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &Selected {
    font-weight: bold;
  }
  img {
    width: 2.4rem;
  }
}

.select {
  height: 4.4rem;
  font-family: inherit;
  font-size: 1.4rem;
  padding: 0 2rem;
  border-radius: 1rem;
}

.button {
  border-radius: 100rem;
  font-size: 1.8rem;
  height: 5rem;
  line-height: 2.8rem;
  padding: 0 3rem;
  font-weight: 400;
}

.marginTop {
  margin-top: 2rem;
}

.success {
  &Container {
    max-width: 52.3rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &Title {
    margin-top: 4rem;
    font-weight: 500;
    font-size: 4.2rem;
    line-height: 4.4rem;
    text-align: center;
    color: #000000;
  }

  &Subtitle {
    margin-top: 2.3rem;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
  }

  &Button {
    margin-top: 4rem;
    max-width: 42rem;
  }

  &Image {
    padding-top: 8rem;
    max-width: 43.7rem;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 650px) {
  .marginTop {
    margin-top: 10px;
  }

  .header {
    height: 84px;

    &Container {
      img {
        width: 90px;
      }
    }
  }

  .section {
    &Title {
      margin-top: 20px;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.5px;
      padding-right: 30px;
    }

    &Subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #1b1c20;
      margin-top: 23px;
      margin-bottom: 23px;
      padding: 0;
    }
  }

  .section {
    height: auto;
    flex-direction: column;
    padding: 0 20px;
    padding-bottom: 40px;

    &Column1 {
      width: 100%;
      margin: 0;
    }

    &BadgesContainer {
      max-width: 100%;
      margin-top: -30px;
      margin-bottom: 30px;
    }

    &Column2 {
      width: 100%;
    }
  }

  .form {
    &Wrapper {
      width: 100%;
      padding: 30px 15px 40px 15px;
    }

    &Button {
      margin-top: 30px;
    }

    &Title {
      font-weight: 700;
      font-size: 22px;
      line-height: 31px;
    }

    &Subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: left;
      margin-top: 10px;
    }

    &Disclaimer {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 0 15px;
      position: relative;
    }

    &Content {
      margin-top: 23px;
    }

    &Image1 {
      top: -10px;
      left: -10px;
      width: 60px;
    }
    &Image2 {
      top: -620px;
      right: -10px;
      width: 70px;
    }
    &Image3 {
      bottom: -20px;
      left: -12px;
      width: 70px;
    }
    &Image4 {
      bottom: -10px;
      right: -10px;
      width: 60px;
    }
  }

  .input {
    height: 40px;
    padding: 0 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-radius: 10px;
    border: 2px solid #f8f8fa;

    &Error {
      border-color: #ff685e;
    }
  }

  .button {
    border-radius: 100rem;
    height: 50px;
    padding: 0 3rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .select {
    height: 40px;
    font-size: 12px;
    padding: 0 20px;
    border-radius: 10px;
  }

  .menuItem {
    height: 40px;
    font-weight: 400;
    font-size: 12px;
    line-height: 40px;
    img {
      width: 20px;
    }
  }

  .backdrop {
    padding-top: 60px;
  }

  .success {
    &Container {
      max-width: 100%;
      width: 100%;
      padding: 0 20px;
    }

    &Title {
      margin-top: 13px;
      font-weight: 500;
      font-size: 26px;
      line-height: 44px;
      text-align: center;
      color: #000000;
    }

    &Subtitle {
      margin-top: 9px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }

    &Button {
      margin-top: 64px;
    }

    &Image {
      padding-top: 100px;
      img {
        width: 100%;
      }
    }
  }
}
