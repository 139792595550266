.buttonBox {
  width: 100%;
  flex-direction: row;
  gap: 1.3rem;

  @media screen and (max-width: 600px) {
    gap: 13px;
  }
}

.primaryButton {
  width: 100%;
  max-width: 23rem;
  height: 6rem;
  text-transform: none;
  color: #fff;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.4rem;
  background-color: #5484ff;
  border-radius: 0.6rem;
  &:hover {
    color: #fff;
    background-color: #4875e8;
  }
  @media screen and (max-width: 600px) {
    max-width: none;
    height: 60px;
    font-size: 16px;
    border-radius: 6px;
  }

  &.contrastColor {
    color: #000000;
    background-color: #ffcc53;
  }
}

.secondaryButton {
  width: 100%;
  max-width: 23rem;
  height: 6rem;
  text-transform: none;
  color: #5484ff;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.4rem;
  border: 1px solid #5484ff;
  background-color: #ffffff;
  border-radius: 0.6rem;
  @media screen and (max-width: 600px) {
    max-width: none;
    height: 60px;
    font-size: 16px;
    border-radius: 6px;
  }

  &.contrastColor {
    color: #000000;
    border: 1px solid #ffffff;
    background-color: #ffffff;
  }
}

.infoText {
  width: 100%;
  margin-top: 1.4rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 600px) {
    font-size: 13px;
    margin-top: 14px;
  }
}
