.landingWrapper {
  color: #1b1c20;
}

html,
body {
  // font-size: 0.625vw; // 1600
  // font-size: 0.694444444vw; // 1440
  position: relative;
}

.container755 {
  max-width: 75.5rem;
}

.container995 {
  max-width: 95.5rem;
  padding: 0;
}

.container1296 {
  max-width: 117rem;
}

.container1360 {
  max-width: 136rem;
}

.footerLinksFlex {
  display: flex;
  column-gap: 1.6rem;
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #fff;
  transition: all 0.1s ease-out;

  a {
    text-decoration: none;
    transition: all 0.1s ease-out;
    &:hover {
      color: #5484ff;
    }
  }

  &Logo {
    width: 11rem;
    img {
      width: 100%;
      max-width: 13rem;
    }
  }

  &MenuButton {
    display: none;
  }

  &Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 9rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &Menu {
    font-weight: 400;
    font-size: 1.8rem;
    color: #1b1c20;
    padding-left: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      margin-right: 3.4rem;
    }

    a:last-child {
      margin-right: 0;
    }
  }

  .authLink {
    text-decoration: none;
    font-size: 1.8rem;
    margin-right: 4.4rem;
  }

  &ButtonPrimary {
    width: 14.5rem;
    height: 5rem;
    text-transform: none;
    color: #fff;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
    background-color: #5484ff;
    border-radius: 0.6rem;
    &:hover {
      color: #fff;
      background-color: #4875e8;
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  &ButtonSecondary {
    width: 14.5rem;
    height: 5rem;
    text-transform: none;
    color: #5484ff;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
    border: 1px solid #5484ff;
    background-color: #ffffff;
    border-radius: 0.6rem;
    @media screen and (max-width: 600px) {
      width: 150px;
      max-width: none;
      height: 40px;
      font-size: 14px;
      border-radius: 6px;
    }
  }
}

.footer {
  &Wrapper {
    background: #efefef;
    padding-top: 4.7rem;
    height: 32.8rem;
  }

  &Flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Menu {
    font-weight: 500;
    font-size: 1.6rem;
    color: #1b1c20;

    a {
      text-decoration: none;
      margin-right: 5rem;
    }

    a:last-child {
      margin-right: 0;
    }
  }

  &Divider {
    width: 100%;
    height: 0.1rem;
    background: #d4d4d8;
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
  }

  &MutedText {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1.6rem;
    color: #797b89;
    a {
      color: #797b89;
      text-decoration: none;
      margin-right: 3.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.section1 {
  &Wrapper {
    padding-top: 12rem;
    padding-bottom: 7.8rem;
  }

  &Grid {
    flex-wrap: nowrap;
  }

  &Animation {
    height: 24rem;
    width: 49rem;
    margin-left: -4.4rem;
    margin-top: -2rem;
  }

  &ImageWrapper {
    width: 60rem;
    position: relative;
  }

  &GridContainer {
    margin-top: 5.5rem;
  }

  &Header {
    font-weight: 700;
    font-size: 5.9rem;
    line-height: 7rem;
    color: #1b1c20;
  }

  &Subtitle {
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3.1rem;
    color: #1b1c20;
    margin-top: -2rem;
    max-width: 40.8rem;
  }

  &Button {
    padding: 0 3.5rem;
    border-radius: 10rem;
    height: 5.9rem;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: center;
    color: #ffffff;
    text-transform: none;
    background: #5484ff;
    margin-top: 4.8rem;
    font-family: inherit;

    &:hover {
      background-color: darken($color: #5484ff, $amount: 8);
    }
  }

  &ButtonSecondary {
    padding: 0 3.5rem;
    border: 1px solid #d6e1ff;
    border-radius: 10rem;
    height: 5.9rem;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: center;
    color: #000;
    text-transform: none;
    background: #fff;
    margin-top: 4.8rem;
    font-family: inherit;
    margin-left: 1rem;

    &:hover {
      background-color: darken($color: #ffffff, $amount: 8);
    }
  }

  &TitleSm {
    margin-top: 13rem;
    font-weight: 700;
    font-size: 3.4rem;
    line-height: 4rem;
    text-align: center;
    color: #1b1c20;
  }

  &FeatureFlex {
    display: flex;
    & .img {
      width: 10rem;
      height: 10rem;
    }
  }

  &FeatureTitle {
    font-weight: 700;
    font-size: 2.5rem;
    color: #1b1c20;
    margin-left: 2.2rem;
  }

  &FeatureBody {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.4rem;
    max-width: 22.2rem;
    margin-left: 2.2rem;

    div {
      border-bottom: 0.1rem solid #cfd9f2;
      padding-bottom: 1.8rem;
    }
  }
  &CTAButtonBox {
    margin-top: 2.4rem;
    width: 90%;
  }

  &Badges {
    margin-top: 3.2rem;

    &Title {
      color: #1b1c20;
      font-size: 2rem;
      font-weight: 500;
      line-height: 3.1rem;
      margin-bottom: 0.8rem;
    }

    &Block {
      flex-direction: row;
      gap: 1.2rem;
      width: 100%;
    }
  }

  &SlackBadge {
    padding: 1rem 1.7rem;
    height: 5.1rem;
    width: 100%;
    max-width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    font-size: 1.6rem;
    line-height: 3.1rem;
    font-weight: 400;
    color: #1b1c20;
    background-color: #f4f7ff;
    border-radius: 1.3rem;

    img {
      width: 2.1rem;
    }
  }

  &MicrosoftBadge {
    padding: 1rem 1.7rem;
    height: 5.1rem;
    width: 100%;
    max-width: 30.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    font-size: 1.6rem;
    line-height: 3.1rem;
    font-weight: 400;
    color: #1b1c20;
    background-color: #f8f8f8;
    border-radius: 1.3rem;

    img {
      width: 2.1rem;
    }

    &Note {
      width: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2rem;
      color: #000;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 3.1rem;
      border-radius: 5rem;
      background: #d7e3fb;
    }
  }
}

.trustBadges {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-top: 7.8rem;

  &Box {
    width: 100%;
  }
  &Item {
    width: 100%;
  }
}

.section2 {
  &Wrapper {
    padding-bottom: 3rem;
  }

  &ContentWrapper {
    background: #f8f8fa;
    width: 91rem;
    height: 46.6rem;
    padding: 2.5rem 0.5rem 0.5rem 6.8rem;
    border-top-right-radius: 6rem;
    border-bottom-right-radius: 6rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
  }

  &TextWrapper {
    padding-right: 2.5rem;
  }

  &ImageWrapper {
    img {
      width: 46.3rem;
    }
  }

  &Subtitle {
    font-weight: 400;
    font-size: 2.4rem;
    color: #1b1c20;
    text-align: center;
  }

  &FeatureTitle {
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.6rem;
    color: #1b1c20;
  }

  &FeatureBody {
    margin-top: 3rem;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  &Wrapper {
    display: flex;
    margin-top: 5.8rem;
  }

  &ContentTabs {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: center;
    color: #000000;
    width: 28rem;
    min-width: 28rem;
    div {
      cursor: pointer;
      padding: 0 3rem;
      height: 11.65rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 6rem;
      border-bottom-left-radius: 6rem;
      transition: all 0.15s ease-out;

      &.active {
        font-weight: 700;
        background: #f8f8fa;
      }
    }
  }
}

.section3 {
  &Wrapper {
    padding-top: 17rem;
    padding-bottom: 18rem;
    overflow: hidden;
  }

  &Title {
    margin: 12rem auto 0 auto;
  }

  &Subtitle {
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 3rem;
    text-align: center;
    color: #000000;
    max-width: 95.4rem;
    margin: 0 auto;
    margin-top: 2.2rem;
  }

  &Flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 98.6rem;
    margin: 0 auto;
    margin-top: 14.5rem;
  }

  &FeatureFlex {
    width: 21rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 700;
    font-size: 2.6rem;
    text-align: center;
    color: #1b1c20;
    height: 19.9rem;
    img {
      width: 10rem;
      height: 10rem;
      margin-bottom: 2.4rem;
    }
  }

  &ComingSoonLabel {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2rem;
    background: #c4d3f0;
    text-align: center;
    color: #000000;
    border-radius: 100rem;
    padding: 0.5rem 1.6rem;
    margin-top: 1rem;
  }

  &SpecWrapper {
    margin-top: 12.8rem;
    display: flex;
    border: 1px solid #cfd9f2;
    border-radius: 48px;
    padding: 5.1rem 4.5rem 6.1rem 4.5rem;
  }

  &SpecColLeft {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 3.2rem;
    color: #1b1c20;
    position: relative;
    min-width: 33.1rem;
    padding-top: 38rem;
  }

  &SpecImageWrapper img {
    position: absolute;
    left: -10rem;
    top: -10rem;
  }

  &SpecIconLinkedin {
    cursor: pointer;
    position: relative;
    top: 0.6rem;
    left: 1.5rem;

    img {
      width: 2.6rem;
    }
  }

  &SpecColRight {
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 3.7rem;
    color: #000000;
    padding-left: 5rem;
    position: relative;
    padding-top: 1.6rem;

    & img {
      z-index: 1;
      position: absolute;
      top: -4.1rem;
      left: 1rem;
    }

    & div {
      position: relative;
      z-index: 2;
    }
  }
}

.section4 {
  &Wrapper {
    background: linear-gradient(90deg, #f2f2f6 0%, #f2f2f6 50%, #5484ff 50%, #5484ff 100%);
    border-radius: 4.8rem;
    padding: 4rem 2rem 2rem 2rem;
    z-index: 10;
    position: relative;
  }

  &Container {
    max-width: 112rem;
  }

  &TitleWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4.2rem;
    div {
      width: 54.4rem;
    }
    div:last-child {
      color: #fff;
    }
  }

  &FeatureRow {
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.6rem;
    color: #000000;
    background: linear-gradient(90deg, #ffffff 0%, #ffffff 50%, #4271e9 50%, #4271e9 100%);
    border-radius: 2rem;
    padding: 2.4rem 3.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    div {
      width: 44rem;
    }
    div:last-child {
      padding-left: 1.2rem;
      color: #fff;
    }
  }
}

.section5 {
  &CTAButtonBox {
    width: 100%;
    margin-top: 7.8rem;
  }

  &Wrapper {
    position: relative;
    padding-top: 19rem;
    padding-bottom: 16.8rem;
  }

  &SliderImageWrapper {
    width: 78.8rem;
    img {
      width: 100%;
    }
  }

  &ArrowWrapper {
    cursor: pointer;
    img {
      width: 11.4rem;
    }
  }

  &Container {
    max-width: 115rem;
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &Bg {
    position: absolute;
    top: -42.5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  &Title {
    font-weight: 700;
    font-size: 4.4rem;
    line-height: 5.6rem;
    text-align: center;
  }

  &Button {
    border-radius: 10rem;
    height: 5.9rem;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: center;
    color: #000000;
    text-transform: none;
    background: #ffcc53;
    width: 294px;
    margin: 0 auto;
    margin-top: 5.8rem;
    font-family: inherit;

    &:hover {
      background-color: darken($color: #ffcc53, $amount: 8);
    }
  }

  &Slider {
    width: 100%;
    margin-top: 6.8rem;
    margin-bottom: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Pager {
    width: 9.2rem;
    height: 1.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20.9rem;
    div {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 1rem;
      background: #dfecff;
      transition: all 0.1s ease-out;
    }

    &Active {
      background: #5484ff !important;
    }
  }
}

.sectionTitle {
  font-weight: 700;
  font-size: 3.4rem;
  line-height: 4.6rem;
  text-align: center;
  color: #1b1c20;
}

.animationRTL {
  animation: right-to-left 0.5s ease-out;
}

.animationLTR {
  animation: left-to-right 0.5s ease-out;
}

@keyframes right-to-left {
  0% {
    opacity: 0;
    // transform: translateX(-30.0rem);
  }

  100% {
    opacity: 1;
    // transform: translateX(0.0rem);
  }
}

@keyframes left-to-right {
  0% {
    opacity: 0;
    // transform: translateX(30.0rem);
  }

  100% {
    opacity: 1;
    // transform: translateX(0.0rem);
  }
}

.fadeIn {
  animation: fade-in 0.55s ease-out;
}

.fadeIn2 {
  animation: fade-in-2 0.55s ease-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animationWidth100 {
  animation: width-100 7s linear;
}

@keyframes width-100 {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.activeLink {
  color: #5484ff;
}

.showSM {
  display: none;
}

.iconInfo {
  width: 2.4rem;
  height: 2.4rem;
  vertical-align: bottom;
}

.banner {
  background: #fff8dd;
  font-weight: 500;
  font-size: 1.8rem;
  color: #1b1c20;
  position: relative;
  height: 6rem;

  & img {
    height: 6rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.bannerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 2;

  & button {
    background: #ffcc53;
    border-radius: 6.6rem;
    width: 18.5rem;
    height: 4rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 4rem;
    color: #000000;
    text-transform: none;
    font-family: 'DM Sans', sans-serif;
    margin-left: 1.5rem;
    &:hover {
      background: darken(#ffcc53, 10);
    }
  }

  span {
    color: #5484ff;
    font-weight: bold;
  }
}

@media screen and (max-width: 600px) {
  .container755 {
    width: 100%;
  }
  .trustBadges {
    flex-wrap: wrap;
    gap: 1.2rem;
    padding: 50px 0 0 0;

    &Box {
      width: 46%;
    }
  }

  .showSM {
    display: block;
  }

  .hideSM {
    display: none !important;
  }

  .footer {
    &Menu {
      font-size: 13px;

      a {
        margin-right: 15px;
      }
    }

    &MutedText {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      & div:last-child {
        margin-top: 10px;
      }
      a {
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &Flex {
      flex-direction: column;
      align-items: center;
      & div:first-child {
        margin-bottom: 5px;
      }
      & img {
        max-width: 80px !important;
        margin-bottom: 12px;
      }
    }

    &Divider {
      height: 1px;
      margin-top: 8px;
      margin-bottom: 24px;
    }

    &Wrapper {
      background: #efefef;
      padding-top: 24px;
      padding-bottom: 24px;
      height: auto;
    }
  }

  .footerLinksFlex {
    margin-top: 24px;
    width: auto;
    column-gap: 10px;
    img {
      width: 24px;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .mobileMenu {
    background: #fff;
    position: absolute;
    z-index: 1000;
    width: 100%;
    left: 0;
    top: 70px;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 48px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    animation: fadein 0.1s ease-out;

    a {
      text-decoration: none;
    }
  }

  .header {
    & .active {
      top: 60px;
    }

    &Menu {
      display: none;
    }

    &Wrapper {
      padding: 0 0;
      height: 70px;
    }

    &Button {
      border-radius: 100px;
      height: 40px;
      font-size: 12px;
      line-height: 15px;
      width: 150px;
      max-width: 150px;
      padding: 0;
    }

    &MenuButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      min-width: 0;
    }

    &Logo {
      width: 90px;
      margin-left: 15px;
      img {
        width: 100%;
        max-width: 90px;
      }
    }
  }
}

.backdrop {
  z-index: 200;
  background: rgba(43, 69, 107, 0.45);
  opacity: 0.6;
}

.card {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: none;
  position: relative;
  padding: 95px 140px 40px 140px;

  &Title {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
  }

  &FinishTitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
  }

  &Subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
  }

  &Body {
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .titleBox {
    margin-bottom: 3rem;
  }

  .section1 {
    &Wrapper {
      padding-top: 88px;
      padding-bottom: 48px;
    }

    &Grid {
      flex-wrap: wrap;
    }

    &BrandsWrapper {
      margin-top: 15px;
      display: flex;
      align-items: flex-start;
      width: 100%;
      justify-content: space-around;
    }

    &GridContainer {
      margin-top: 25px;
    }

    &Header {
      font-weight: 700;
      font-size: 42px;
      line-height: 52px;
    }

    &Subtitle {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      margin-top: -45px;
      position: relative;
      text-align: left;
    }

    &Button {
      border-radius: 100px;
      height: 50px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin-top: 28px;
      width: 100%;

      &Secondary {
        border-radius: 100px;
        height: 50px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin-top: 28px;
        width: 100%;
        margin-left: 0;
        margin-top: 12px;
      }
    }

    &CTAButtonBox {
      margin-top: 24px;
      width: 100%;
    }

    &Badges {
      margin-top: 22px;
      &Title {
        font-size: 18px;
        margin-bottom: 15px;
      }
      &Block {
        flex-direction: column;
      }
    }

    &SlackBadge {
      max-width: 160px;
      margin: 0 auto;
      height: 51px;
      padding: 0 1rem;
      font-size: 14px;
      line-height: 18px;
      border-radius: 13px;

      img {
        width: 21px;
      }
    }

    &MicrosoftBadge {
      max-width: 300px;
      margin: 0 auto;
      margin: 0 auto;
      height: 51px;
      padding: 0 1rem;
      font-size: 14px;
      line-height: 18px;
      border-radius: 13px;

      img {
        width: 21px;
      }

      &Note {
        font-size: 10px;
        height: 20px;
        width: auto;
        padding: 0 10px;
      }
    }

    &ImageWrapper {
      width: 100%;
      margin-top: 52px;
    }

    &TitleSm {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin-top: 32px;
    }

    &FeatureFlex {
      padding: 0 20px;
      & .img {
        width: 46px;
        height: 46px;
      }
    }

    &FeatureTitle {
      font-weight: 700;
      font-size: 16px;
      line-height: 15px;
      margin-left: 15px;
    }

    &FeatureBody {
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      margin-top: 2px;
      margin-bottom: 21px;
      margin-left: 15px;

      div {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  .sectionTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
  }

  .section3 {
    &Wrapper {
      padding-top: 40px;
      padding-bottom: 80px;
    }

    &Title {
      margin: 50px auto 0 auto;
      width: 300px;
    }

    &Subtitle {
      margin-top: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &Flex {
      margin-top: 60px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &FeatureFlex {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      height: 100px;

      img {
        width: 46px;
        height: 46px;
        margin-bottom: 13px;
      }
    }

    &ComingSoonLabel {
      font-size: 8px;
      line-height: 9px;
      padding: 2px 10px;
      margin-top: 8px;
    }

    &SpecWrapper {
      margin-top: 27px;
      border: 1px solid #cfd9f2;
      border-radius: 20px;
      padding: 24px 15px 14px 15px;
      flex-direction: column-reverse;
    }

    &SpecColLeft {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      min-width: 0;
      padding-top: 16px;
      display: flex;
      align-items: center;
    }

    &SpecImageWrapper {
      width: 100px;
      height: 100px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-right: 18px;
      img {
        position: initial;
        width: 240px !important;
        margin-top: 120px;
        margin-right: 70px;
      }
    }

    &SpecIconLinkedin {
      position: static;
      margin-top: 9px;
      display: block;

      img {
        width: 26px;
      }
    }

    &SpecColRight {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding-left: 0;

      & img {
        width: 113px;
        top: -16px;
        left: -5px;
      }

      & div {
        position: relative;
        z-index: 2;
      }
    }
  }

  .section4 {
    &Wrapper {
      border-radius: 30px;
      padding: 23px 14px 31px 14px;
    }

    &SwitchFlex {
      display: flex;
      justify-content: space-around;
      margin-top: 30px;
    }

    &SwitchButton {
      font-weight: 700;
      font-size: 14px;
      line-height: 12px;
      display: flex;
      align-items: center;

      img {
        margin-left: 8px;
        width: 18px;
        height: 18px;
      }
    }

    &ArrowReverse {
      color: #fff;
      img {
        margin-left: 0;
        margin-right: 8px;
        transform: rotate(180deg);
      }
    }

    &Container {
      max-width: auto;
      width: 200vw;
    }

    &TitleWrapper {
      margin-bottom: 27px;
    }

    &FeatureRow {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      border-radius: 16px;
      padding: 12px 20px;
      margin-bottom: 5px;

      div:first-child {
        padding-right: 10px;
      }

      div:last-child {
        padding-left: 20px;
      }
    }
  }

  .section5 {
    &CTAButtonBox {
      margin-top: 78px;
    }

    &Wrapper {
      padding-top: 72px;
      padding-bottom: 70px;
    }

    &Container {
      justify-content: space-between;
    }

    &SliderImageWrapper {
      padding: 0 5px;
      width: auto;
      img {
        width: 100%;
      }
    }

    &Button {
      border-radius: 100px;
      height: 50px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
      width: 100%;
    }

    &Slider {
      margin-top: 65px;
      margin-bottom: 65px;
    }

    &ArrowWrapper {
      img {
        width: 36px;
      }
    }

    &Pager {
      width: 41px;
      height: 7px;
      margin-bottom: 55px;
      div {
        width: 7px;
        height: 7px;
      }
    }

    &Title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
    }

    &Container {
      max-width: 100%;
    }
  }

  .section2 {
    background: #f8f8fa;
    padding-top: 20px;

    &ImageControlsWrapper {
      display: flex;
      align-items: center;
    }

    &Wrapper {
      margin-top: 0;
      padding-top: 5px;
      padding-bottom: 32px;
    }

    &ContentTabs {
      display: none;
    }

    &ContentWrapper {
      padding: 0;
      flex-direction: column-reverse;
      height: auto;
    }

    &TextWrapper {
      padding: 0;
    }

    &Subtitle {
      font-size: 14px;
      line-height: 20px;
    }

    &Feature {
      margin-bottom: 0;
    }

    &FeatureTitle {
      font-weight: 700;
      font-size: 20px;
      line-height: 22px;
      justify-content: center;
      text-align: center;
      width: 100%;
      margin-top: 15px;
    }

    &FeatureBody {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      margin-top: 16px;
    }

    &ImageWrapper {
      width: 280px;
      padding-right: 0;

      img {
        width: 100%;
      }
    }
  }

  .card {
    padding: 40px 20px;
    padding-top: 80px;

    &Title {
      font-size: 28px;
      line-height: 32px;
    }

    &FinishTitle {
      font-size: 24px;
      line-height: 36px;
    }

    &Subtitle {
      font-size: 16px;
      line-height: 29px;
    }

    &Body {
      font-size: 16px;
      line-height: 29px;
    }
  }

  .iconInfo {
    width: 22px !important;
    height: 22px !important;
    vertical-align: middle !important;
  }

  .banner {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    height: 60px;

    & img {
      height: 60px;
      position: absolute;
      transform: scaleX(-1);
    }
  }

  .bannerWrapper {
    height: 60px;

    & button {
      border-radius: 100px;
      min-width: 100px;
      min-height: 32px;
      width: 120px;
      height: 38px;
      font-weight: 500;
      font-size: 12px;
      margin-left: 8px;
    }
  }

  .hideSM {
    display: none;
  }
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.infoLink {
  color: #5484ff;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

:global {
  .headerActive {
    top: 0;
  }
}
