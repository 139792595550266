.root {}

.card {
  background-color: #fff;
  box-shadow: none !important;
  width: 100%;
  display: flex;
  max-width: 660px;
}

.typography {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  font-family: Montserrat, sans-serif;
  width: 600px; // TODO: Find another way to make a wide toast
  max-width: 660px;
}

.actionRoot {
  justify-content: space-between;
  padding: 14px 8px 14px 16px;
  max-width: 660px;
}

.icons {
  margin-left: auto;
  display: flex;
}

.expand {
  padding: 8px 8px;
  transform: rotate(0deg);
  transition: transform 0.5s;
}

.expandOpen {
  transform: rotate(180deg);
}

.collapse {
  padding: 16;
}

.checkIcon {
  color: #b3b3b3;
  font-size: 20;
  padding-right: 4;
}

button {
  padding: 0;
  text-transform: none;
}

.timerwrapper {
  margin-right: 15px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
}

.timer {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.text {
  color: #aaa;
}

.value {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 26px;
  text-align: center;
}

.actionWrapper {
  margin-left: 20px;
  margin-right: 20px;
}
